import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSingleDataSource,
} from '../types/Response';
import BaseResource from './base';
import { giftNote } from './endPoints';
import type { GiftNoteEntity } from '../types/GiftNote';

type Entity = GiftNoteEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;


export class GiftNoteResource extends BaseResource {
  /**
   * Strapi list
   */
  list(options?: AxiosRequestConfig) {
    return this.client.get<
    PaginatedDataType,
      PaginatedResponseType
    >(giftNote.list(), options);
  }

  /**
   * Strapi get
   */
  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<
      SingleDataType,
      SingleResponseType
    >(giftNote.get(id), options);
  }

  /**
   * Strapi create
   */
  create(payload: Entity, options?: AxiosRequestConfig) {
    return this.client.post<
    SingleDataType,
      SingleResponseType
    >(giftNote.create(), payload, options);
  }

  /**
   * Strapi update
   */
  update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<
    SingleDataType,
      SingleResponseType
    >(giftNote.update(id), payload, options);
  }

  /**
   * Strapi delete
   */
  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<
    SingleDataType,
      SingleResponseType
    >(giftNote.delete(id), options);
  }
}
