const prefix = "gift-wallet/";

export const orderLog = {
  list: () => `${prefix}order-logs`,
  get: (id: string) => `${prefix}order-logs/${id}`,
  getBy: () => `${prefix}order-logs`,
  create: () => `${prefix}order-logs`,
  update: (id: string) => `${prefix}order-logs/${id}`,
  delete: (id: string) => `${prefix}order-logs/${id}`,
};

export const giftNote = {
  list: () => `${prefix}gift-notes`,
  get: (id: string) => `${prefix}gift-notes/${id}`,
  getBy: () => `${prefix}gift-notes`,
  create: () => `${prefix}gift-notes`,
  update: (id: string) => `${prefix}gift-notes/${id}`,
  delete: (id: string) => `${prefix}gift-notes/${id}`,
};

export const gift = {
  list: () => `${prefix}gifts`,
  listByCustomerIdRedeemableGrouped: (id: string) => `${prefix}gifts/customer/${id}/redeemable-grouped`,
  listByCustomerIdRedeemable: (id: string) => `${prefix}gifts/customer/${id}/redeemable`,
  listByCustomerIdActive: (id: string) => `${prefix}gifts/customer/${id}/active`,
  listByCustomerIdUsed: (id: string) => `${prefix}gifts/customer/${id}/used`,
  get: (id: string) => `${prefix}gifts/${id}`,
  getBy: () => `${prefix}gifts`,
  getRemoteGift: (id: string) => `${prefix}gifts/${id}/remote`,
  getRemoteEGift: (id: string) => `${prefix}gifts/${id}/egift`,
  sendEmail: (id: string) => `${prefix}gifts/${id}/email-egift`,
  cancel: (id: string) => `${prefix}gifts/${id}/cancel`,
  create: () => `${prefix}gifts`,
  update: (id: string) => `${prefix}gifts/${id}`,
  delete: (id: string) => `${prefix}gifts/${id}`,
};

export const wallet = {
  list: () => `${prefix}wallets`,
  get: (id: string) => `${prefix}wallets/${id}`,
  create: () => `${prefix}wallets`,
  update: (id: string) => `${prefix}wallets/${id}`,
  delete: (id: string) => `${prefix}wallets/${id}`,
  redeem: (id: string) => `${prefix}wallets/${id}/redeem`,

  getBy: () => `${prefix}wallets`,
  getByInkindRouteId: () => `${prefix}wallets`,
  getByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
  updateByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
};

export const platformManagementEndPoints = {
  gift,
  wallet,
  orderLog,
  orderNote: giftNote
}
