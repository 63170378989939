/**
 * GiveInKind Tailwind Theme
 *
 * The default TailWind configuration can be found here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
 */

const sizeScale = {
  0: '0',
  1: '.25rem',
  2: '.5rem',
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  32: '8rem',
  40: '10rem',
  48: '12rem',
  56: '14rem',
  64: '16rem',
  full: '100%',
};

const screens = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

module.exports = {
  purge: false,
  prefix: 'tw-',
  theme: {
    app: {
      desktopHeaderHeight: '70px',
      mobileHeaderHeight: '50px',
    },
    screens: {
      ...Object.keys(screens).reduce(
        (acc, key) => ({
          ...acc,
          [`${key}-down`]: { max: `${screens[key] - 1}px` },
          [`${key}-down-landscape`]: { raw: `(max-height: ${screens[key]}px) and (orientation: landscape)` },
          [key]: `${screens[key]}px`,
        }),
        {}
      ),
      print: { raw: 'print' },
      screen: { raw: 'screen' },
      'md-down-landscape': { raw: '(max-height: 768px) and (orientation: landscape)' },
    },
    fontFamily: {
      body: ['Roboto', '"Open Sans"', '"Helvetica Neue"', 'sans-serif'], // secondary use-typography TODO: remove this comment after SPA migration
      heading: ['Dosis', 'Roboto', '"Open Sans"', '"Helvetica Neue"', 'sans-serif'], // primary use-typography
      ui: ['Dosis', 'Roboto', '"Open Sans"', '"Helvetica Neue"', 'sans-serif'], // primary use-typography
      messages: ['Arial', '"Open Sans"', 'sans-serif'], // primary use-typography
    },

    container: {
      center: true,
      padding: {
        default: '1rem',
      },
    },
    iconSize: {
      // NOTE: these icon sizes must be defined in pixels otherwise Firefox will just display them at full container size
      xs: '16px',
      sm: '20px',
      base: '24px',
      lg: '28px',
      xl: '32px',
      '2xl': '40px',
      '3xl': '52px',
    },
    boxShadow: {
      none: 'none',
      1: '0 1px 10px 0 rgba(0,0,0,.2);', // TODO: remove
      xs: '0px 0px 0px 1px rgba(59, 65, 66, 0.15)',
      sm: '0px -1px 2px rgba(59, 65, 66, 0.101399), 0px 2px 3px rgba(59, 65, 66, 0.12)',
      //eslint-disable-next-line
      base: '0px -1px 2px rgba(59, 65, 66, 0.07), 0px 5px 10px -2px rgba(59, 65, 66, 0.1), 0px 3px 5px -1px rgba(59, 65, 66, 0.1)',
      //eslint-disable-next-line
      lg: '0px -1px 3px 1px rgba(59, 65, 66, 0.07), 0px 5px 10px -3px rgba(59, 65, 66, 0.07), 0px 10px 17px -3px rgba(59, 65, 66, 0.1)',
      //eslint-disable-next-line
      xl: '0px 0px 5px 1px rgba(59, 65, 66, 0.1), 0px 25px 25px -5px rgba(59, 65, 66, 0.07), 0px 15px 10px -5px rgba(59, 65, 66, 0.05)',
      '2xl': '0px 0px 8px -1px rgba(59, 65, 66, 0.1), 0px 25px 50px -12px rgba(59, 65, 66, 0.25)',
      '3xl': '0px 0px 48px 8px rgba(59, 65, 66, 0.25)',
    },
    minWidth: sizeScale,
    // maxWidth: sizeScale,
    minHeight: sizeScale,
    // maxHeight: sizeScale,
    colors: {
      transparent: 'transparent',
      current: 'currentColor',

      black: '#3B4142',
      white: '#fff',
      red: '#FF0000',
      heart: '#ef5c84',
      primary: {
        50: '#e2feff',
        100: '#c3fdff',
        200: '#a6fcff',
        300: '#7ef7fa',
        400: '#62edf0',
        500: '#41cbce',
        600: '#35a7a9',
        700: '#1d8487',
        800: '#086a6d',
        900: '#004849',
      },
      secondary: {
        50: '#FFEEE3',
        100: '#FDDDC4',
        200: '#FCCDA8',
        300: '#FABC8D',
        400: '#F9AC74',
        500: '#FF9E5A',
        600: '#F58536',
        700: '#C86A28',
        800: '#834811',
        900: '#5D3813',
      },
      gray: {
        50: '#999',
        100: '#888',
        200: '#777',
        300: '#666',
        400: '#555',
        500: '#444',
        600: '#333',
        700: '#222',
        800: '#111',
        900: '#000',
      },
      lgray: {
        50: '#f5f5f5',
        100: '#f0f0f0',
        200: '#eee',
        300: '#ddd',
        400: '#ccc',
        500: '#bbb',
        600: '#aaa',
        700: '#999',
        800: '#888',
        900: '#777',
        other: '#8fb3b4',
      },
      neutral: {
        50: '#F7F9F9',
        100: '#EFF3F3',
        200: '#E6EBEB',
        300: '#DAE3E3',
        400: '#CED9D9',
        500: '#BCC7C8',
        600: '#9EABAD',
        700: '#7F8B8D',
        800: '#5B6669',
        900: '#3B4142',
      },
      danger: {
        50: '#fdebf0',
        100: '#faceda',
        200: '#f7aec2',
        300: '#f48da9',
        400: '#f17496',
        500: '#ef5c84',
        600: '#ed547c',
        700: '#eb4a71',
        800: '#e84167',
        900: '#e43054',
      },
      warn: {
        50: '#FFF9EB',
        100: '#FFF3D7',
        200: '#FFE5A6',
        300: '#FFDD89',
        400: '#FFDC87',
        500: '#FFD368',
        600: '#E9B73D',
        700: '#D39E1F',
        800: '#A77D17',
        900: '#745725',
      },
      success: {
        50: '#F2FFE7',
        100: '#E8F4DE',
        200: '#CEE9B7',
        300: '#B0D88F',
        400: '#A5CC85',
        500: '#8FBB6A',
        600: '#7BAB52',
        700: '#629338',
        800: '#477223',
        900: '#284114',
      },
      info: {
        50: '#F2F6FF',
        100: '#E0ECFF',
        200: '#C7DFFF',
        300: '#9AC8FF',
        400: '#71AAFF',
        500: '#5F93F3',
        600: '#427EE2',
        700: '#3771C4',
        800: '#09497E',
        900: '#012846',
      },
      purple: {
        50: '#FEF4FF',
        100: '#F1D7FF',
        200: '#E4B4FD',
        300: '#CD8AEF',
        400: '#B069D4',
        500: '#9049B4',
        600: '#9F57C4',
        700: '#753994',
        800: '#5E2D79',
        900: '#381B48',
      },
      // pink -> Home Services
      pink: {
        700: '#d936ab',
      },
      // dark purple ? -> Custom
      darkPurple: {
        700: '#544396',
      },
      // dark orange ? -> Transportation
      darkOrange: {
        700: '#cd5140',
      },
    },
    extend: {
      margin: {
        48: '12rem',
        64: '16rem',
      },
      borderRadius: {
        sm: '0.25rem',
        DEFAULT: '0.5rem',
        md: '1rem',
        lg: '2rem',
      },
      borderWidth: {
        DEFAULT: '1px',
        0: '0',
        2: '2px',
        3: '3px',
        4: '4px',
        6: '6px',
        8: '8px',
      },
      zIndex: {
        sticky: 50,

        banner: 2147482900,
        appNav: 2147482950,
        belowIntercom: 2147482999, // for reference
        intercom: 2147483000, // for reference
        drawerBackdrop: 2147483197,
        drawer: 2147483198,
        modalBackdrop: 2147483199,
        modal: 2147483200,
        modalDrag: 2147483201,
        modalDrawerBackdrop: 2147483204,
        modalDrawer: 2147483205,
        modalDrawerDrag: 2147483206,
        modalAuth: 2147483210, // login/signup/forgot password should go on top
        interstitial: 2147483547,
        dialogs: 2147483548,
        datePicker: 2147483560,
        popovers: 2147483570,
        lightbox: 2147483580,
        devtools: 2147483600,
        nprogress: 2147483601,
        focusring: 2147483602,
        belowUnbounce: 2147483645,
        unbounce: 2147483646,
        max: 2147483647,
        above: 1,
        below: -1,
      },
      transitionProperty: {
        size: 'width, height',
      },
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
      circle: 'circle',
    },
  },
  variants: {},
  plugins: [],
};
