import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSingleDataSource,
} from '../types/Response';
import BaseResource from './base';
import { orderLog } from './endPoints';
import type { OrderLogEntity } from '../types/OrderLog';

type Entity = OrderLogEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;


export class OrderLogResource extends BaseResource {
  /**
   * Strapi list
   */
  list(options?: AxiosRequestConfig) {
    return this.client.get<
    PaginatedDataType,
      PaginatedResponseType
    >(orderLog.list(), options);
  }

  /**
   * Strapi get
   */
  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<
      SingleDataType,
      SingleResponseType
    >(orderLog.get(id), options);
  }

  /**
   * Strapi create
   */
  create(payload: Entity, options?: AxiosRequestConfig) {
    return this.client.post<
    SingleDataType,
      SingleResponseType
    >(orderLog.create(), payload, options);
  }

  /**
   * Strapi update
   */
  update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<
    SingleDataType,
      SingleResponseType
    >(orderLog.update(id), payload, options);
  }

  /**
   * Strapi delete
   */
  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<
    SingleDataType,
      SingleResponseType
    >(orderLog.delete(id), options);
  }

  /**
   * Strapi get by uuid
   */
  async getByUuid(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<
      SingleDataType,
      SingleResponseType
    >(orderLog.getBy(), {
      ...options,
      ... {
        params: {
          filters: {
            uuid: {
              $eq: id
            }
          },
          ...options?.params,
        }
      }
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }

  /**
   * Strapi get by orderId
   */
  async getByOrderId(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<
      SingleDataType,
      SingleResponseType
    >(orderLog.getBy(), {
      ...options,
      ... {
        params: {
          filters: {
            orderId: {
              $eq: id
            }
          },
          ...options?.params,
        }
      }
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }
}
