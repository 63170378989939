import type { AxiosRequestConfig } from 'axios';
import type { PlatformManagementApiResponse, StrapiPagedDataSource, StrapiSingleDataSource } from '../types/Response';
import BaseResource from './base';
import { gift } from './endPoints';
import type { GiftEntity, SendEGiftEmailPayload } from '../types/Gift';
import type { GyGiftEntity } from '@gik/giftya-api-js';

type Entity = GiftEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class GiftResource extends BaseResource {
  /**
   * Strapi list
   */
  list(options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.list(), options);
  }

  /**
   * Strapi get
   */
  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<GiftEntity, SingleResponseType>(gift.get(id), options);
  }

  /**
   * Strapi create
   */
  create(payload: Entity, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, SingleResponseType>(gift.create(), payload, options);
  }

  /**
   * Strapi update
   */
  update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<SingleDataType, SingleResponseType>(gift.update(id), payload, options);
  }

  /**
   * Strapi delete
   */
  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<SingleDataType, SingleResponseType>(gift.delete(id), options);
  }

  /**
   * Strapi get by uuid
   */
  async getByUuid(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<SingleDataType, SingleResponseType>(gift.getBy(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }

  listByCustomerIdRedeemableGrouped(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(
      gift.listByCustomerIdRedeemableGrouped(id),
      options
    );
  }

  listByCustomerIdRedeemable(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdRedeemable(id), options);
  }

  listByCustomerIdActive(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdActive(id), options);
  }

  listByCustomerIdUsed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdUsed(id), options);
  }

  /**
   * Get remote gift details
   */
  async getRemoteGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyGiftEntity>>(gift.getRemoteGift(id), options);

    return response;
  }

  async getRemoteEGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyGiftEntity>>(
      gift.getRemoteEGift(id),
      options
    );

    return response;
  }

  /**
   * Strapi create
   */
  cancel(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, boolean>(gift.cancel(id), undefined, options);
  }

  sendEmail(id: string, payload: Partial<SendEGiftEmailPayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, SingleResponseType>(gift.sendEmail(id), payload, options);
  }
}
