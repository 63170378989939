import type { SetState } from 'zustand';
import create from 'zustand';

// remember to forward process.env vars from next.config.js
const initialState = {
  REACT_APP_URL: process.env.REACT_APP_URL,
  ADGLARE_NAME: process.env.ADGLARE_NAME,
  ADGLARE_ZONES: process.env.ADGLARE_ZONES,
  DD_LOGS_CLIENT_TOKEN: process.env.DD_LOGS_CLIENT_TOKEN,
  DD_RUM_CLIENT_TOKEN: process.env.DD_RUM_CLIENT_TOKEN,
  DD_RUM_APPLICATION_ID: process.env.DD_RUM_APPLICATION_ID,
  DD_SERVICE_NAME: process.env.DD_SERVICE_NAME,
  DD_RUM_SAMPLE_RATE: process.env.DD_RUM_SAMPLE_RATE,
  FULLSTORY_ORG_ID: process.env.FULLSTORY_ORG_ID,
  GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
  MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN,
  LOGGER_ENABLED: process.env.LOGGER_ENABLED,
  GOOGLE_APP_ID: process.env.GOOGLE_APP_ID,
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
  WP_CUSTOM_API_URL: process.env.WP_CUSTOM_API_URL,
  STRIPE_API_KEY: process.env.STRIPE_API_KEY,
  BASE_URL: process.env.BASE_URL,
  API_CACHE_URL: process.env.API_CACHE_URL,
  DOTNET_API_URL: process.env.DOTNET_API_URL,
  WP_API_URL: process.env.WP_API_URL,
  WP_ACF_V3_API_URL: process.env.WP_ACF_V3_API_URL,
  KY_LOGGER_ENABLED: process.env.KY_LOGGER_ENABLED,
  SUSPENSE_ENABLED: process.env.SUSPENSE_ENABLED,
  VERCEL_ENV: process.env.VERCEL_ENV,
  VERCEL_URL: process.env.VERCEL_URL,
  NEXT: process.env.NEXT,
  NODE_ENV: process.env.NODE_ENV,
  SHOW_ERROR_PAGE_STACK_TRACE: process.env.SHOW_ERROR_PAGE_STACK_TRACE,
  ASSETS_URL: process.env.ASSETS_URL,
  CDN_ASSETS_URL: process.env.CDN_ASSETS_URL,
  ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY: process.env.ALGOLIA_SEARCH_API_KEY,
  ALGOLIA_INDEX_INKINDS: process.env.ALGOLIA_INDEX_INKINDS,
  ALGOLIA_INDEX_PRODUCTS: process.env.ALGOLIA_INDEX_PRODUCTS,
  ALGOLIA_INDEX_POSTS: process.env.ALGOLIA_INDEX_POSTS,
  ALGOLIA_INDEX_INTERCOM_ARTICLES: process.env.ALGOLIA_INDEX_INTERCOM_ARTICLES,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  ADMIN: process.env.ADMIN,
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
  PG_USE_DESIGN_UPLOADER: process.env.PG_USE_DESIGN_UPLOADER,
  WC_API_URL: process.env.WC_API_URL,
  APP_UPDATE_POLLING: process.env.APP_UPDATE_POLLING,
  DISABLE_CACHED_LOCAL_DOTNET_REQUESTS: process.env.DISABLE_CACHED_LOCAL_DOTNET_REQUESTS,
  USE_IMPACT_RADIUS: process.env.USE_IMPACT_RADIUS,
  USE_MATOMO: process.env.USE_MATOMO,
  USE_ALLURE_SECURITY_BEACON: process.env.USE_ALLURE_SECURITY_BEACON,
  USE_EDITABLE_CART: process.env.USE_EDITABLE_CART,
  USE_ANONYMOUS_DONATIONS: process.env.USE_ANONYMOUS_DONATIONS,
  CHECKOUT_HIDE_ADD_MORE_BUTTONS: process.env.CHECKOUT_HIDE_ADD_MORE_BUTTONS,
  GOOGLE_PAY_ENV: process.env.GOOGLE_PAY_ENV,
  ENABLE_GOOGLE_PAY: process.env.ENABLE_GOOGLE_PAY,
  GOOGLE_PAY_MERCHANT_ID: process.env.GOOGLE_PAY_MERCHANT_ID,
  GOOGLE_PAY_MERCHANT_NAME: process.env.GOOGLE_PAY_MERCHANT_NAME,
  ENABLE_APPLE_PAY: process.env.ENABLE_APPLE_PAY,
  WALLET_ENABLED: process.env.WALLET_ENABLED,
  PLATFORM_API_BASE_URL: process.env.PLATFORM_API_BASE_URL,
  PLATFORM_API_KEY: process.env.PLATFORM_API_KEY,
};

export type Environment = typeof initialState;
export type EnvKey = keyof Environment;
export type EnvStore = Readonly<Environment> & {
  set(key: EnvKey, value?: string): void;
  export(): Partial<Environment>;
  import(env: Environment): void;
};

export const useEnvStore = create<EnvStore>((_set: SetState<EnvStore>) => ({
  ...initialState,
  set: (key, value) =>
    _set(state => ({
      ...state,
      [key]: value,
    })),
  export: () => {
    const state: EnvStore = { ...useEnvStore.getState() };
    delete state.set;
    delete state.export;
    delete state.import;

    Object.keys(state).forEach(key => {
      if (!state[key]) delete state[key];
    });

    return state as Partial<Environment>;
  },

  import: env => {
    const empty = Object.keys(initialState).reduce(
      (previousValue, key) => ({
        ...previousValue,
        [key]: null,
      }),
      {}
    );

    _set(() => ({
      ...empty,
      ...env,
    }));
  },
}));
