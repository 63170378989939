import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'wallet';

const translations = {
  title: 'InKind Wallet',
  btnSeeAllSupporters: 'See all supporters',
  tabToRedeem: 'To Redeem',
  tabCardLinked: 'Card Linked',
  tabOther: 'Other',
  'toRedeem-emptyWalletLine1': 'There are currently no gifts to redeem in your Wallet.',
  'toRedeem-emptyWalletLine2':
    'Gifts linked to your payment card will appear in <1>Card Linked</1>. All other redeemed gifts will appear in Other.',
  'cardLinked-emptyWalletLine1': 'There are currently no active gifts linked to your debit or credit card.',
  'cardLinked-emptyWalletLine2': 'Visit To Redeem to select gifts to link to your payment card.',
  'other-emptyWalletLine1': 'There are currently no active Visa cards or eGifts in your Wallet.',
  'other-emptyWalletLine2':
    'Visit To Redeem to select gifts to redeem, or visit <1>Card Linked</1> to view gifts that have been linked to your payment card.',
  logoutBtn: 'Logout',
  supportersTitle: 'Supporters Details',
  backToWallet: 'Return to Wallet',
};
export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
