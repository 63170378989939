import type { AxiosInstance, CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { GiftResource } from './api/gift';
import { OrderLogResource } from './api/orderLog';
import { GiftNoteResource } from './api/giftNote';
import { WalletResource } from './api/wallet';

export type PlatformManagementJsClientOptions = {
  authorization?: string;
  errorInterceptor?: boolean;
  authInterceptor?: boolean;
};

export class PlatformManagementJsClient {
  public client: AxiosInstance;

  public gift: GiftResource;

  public orderLog: OrderLogResource;

  public giftNote: GiftNoteResource;

  public wallet: WalletResource;

  private defaultConfig: CreateAxiosDefaults = {
    withCredentials: true,
  };

  constructor(config: CreateAxiosDefaults = {}, options: PlatformManagementJsClientOptions = {}) {
    this.client = this.createHttpClient(config, options);
    this.gift = new GiftResource(this.client);
    this.orderLog = new OrderLogResource(this.client);
    this.giftNote = new GiftNoteResource(this.client);
    this.wallet = new WalletResource(this.client);
  }

  private createHttpClient(config: CreateAxiosDefaults = {}, options?: PlatformManagementJsClientOptions) {
    const httpClient = axios.create(Object.assign(this.defaultConfig, config));

    // register auth interceptor
    if (options?.authInterceptor !== false) {
      httpClient.interceptors.request.use(async _config => {
        const newConfig = _config;
        if (options?.authorization) {
          newConfig.headers['authorization'] = options?.authorization;
        }
        return newConfig;
      });
    }

    // register error interceptor
    if (options?.errorInterceptor !== false) {
      httpClient.interceptors.response.use(
        response => response,
        error => Promise.reject(error)
      );
    }

    return httpClient;
  }
}
