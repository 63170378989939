import { PlatformManagementJsClient } from '@gik/platform-management-api-js';
import React from 'react';

export type platformManagementClientContextState = {
  client: PlatformManagementJsClient;
};

const platformManagementClientContext = React.createContext<platformManagementClientContextState | null>(null);

/**
 * This hook gives you access to context of {@link PlatformManagementClientProvider}.
 */
export const usePlatformManagementClient = () => {
  const context = React.useContext(platformManagementClientContext);
  if (!context) {
    throw new Error('usePlatformManagementClient must be used within a PlatformManagementClientProvider');
  }
  return context;
};

export type PlatformManagementClientProviderProps = {
  baseUrl: string;
  apiKey: string;

  /**
   * @ignore
   */
  children: React.ReactNode;

  /**
   * Number of times to retry a request if it fails.
   *
   * @defaultValue 3
   */
  maxRetries?: number;

  projectPrefix?: string;
  systemPrefix?: string;
};

/**
 * The `PlatformManagementClientProvider` must be used at the highest possible point in the React component tree. Using any of `medusa-react`'s hooks or providers requires having `MsClientProvider`
 * higher in the component tree.
 */
export const PlatformManagementClientProvider = ({
  baseUrl,
  apiKey,
  children,
}: PlatformManagementClientProviderProps) => {
  const httpClient = new PlatformManagementJsClient(
    {
      baseURL: baseUrl,
    },
    {
      authorization: `Bearer ${apiKey}`,
    }
  );
  return (
    <platformManagementClientContext.Provider
      value={{
        client: httpClient,
      }}
    >
      {children}
    </platformManagementClientContext.Provider>
  );
};
